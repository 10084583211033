import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import loadable from '@loadable/component';

import {
	Container,
	Grid,
	Card,
	CardHeader,
	CardContent,
	Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import FormModalContext from '../context/FormModalContext';

// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#5A7184',
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '6px',
		// maxWidth: '289px',
		position: 'relative',
		// paddingTop: '2.75rem',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		transition: 'transform 0.25s',
	},
	button: {
		padding: '6px 55px',
		marginTop: '1rem',
		opacity: 1,
		fontSize: '1.1rem',
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 700,
		textAlign: 'center',
		color: '#183B56',
	},
	pos: {
		textAlign: 'center',
		color: '#4B5B69',
	},
}));

const LoginCard = ({ card }) => {
	const classes = useStyles();

	return (
		<Grid item xs={12} md={6} lg={4}>
			<Card className={classes.root} elevation={3} style={{ height: '240px' }}>
				<CardHeader
					style={{
						background: card.cardAccentColor[0]?.hexValue ?? '#002D5C',
						padding: '8px',
						width: '100%',
					}}
				/>
				<CardContent style={{ padding: '3rem 2rem' }}>
					<Grid
						container
						direction='column'
						alignItems='center'
						justifyContent='space-between'
						spacing={2}
						item
						xs={12}>
						{/* <Grid item>
							<Typography
								variant='h6'
								color='primary'
								className={classes.title}>
								{card.title}
							</Typography>
						</Grid> */}

						<GatsbyImage
							image={card.logo?.asset?.gatsbyImageData}
							imgStyle={{ objectFit: 'contain' }}
							style={{
								height: '100%',
								width: '100%',
							}}
						/>

						<Grid item>
							<a
								href={card.ctaLink.current}
								target='_blank'
								style={{ textDecoration: 'none' }}>
								<Button
									variant='contained'
									size='large'
									color='primary'
									className={classes.button}
									style={{
										background: card.cardAccentColor[0]?.hexValue ?? '#002D5C',
									}}>
									{card.ctaText}
								</Button>
							</a>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

const LoginPage = ({ data, location }) => {
	const login = data.login.edges[0].node;
	const { formModalOpen } = useContext(FormModalContext);

	const { hero, metaTitle, metaDescription, loginCards } = login;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} login={true} />
			<Container style={{ marginBottom: '4rem' }}>
				<Grid
					container
					direction='row'
					spacing={2}
					style={{ paddingBottom: '8rem' }}>
					{loginCards.map((card, index) => (
						<LoginCard key={index} card={card} />
					))}
				</Grid>
				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					/>
				) : null}
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query LoginPageQuery {
		login: allSanityLogin {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					loginCards {
						_key
						title
						cardAccentColor {
							hexValue
						}
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED, height: 70)
							}
						}
						ctaText
						ctaLink {
							current
						}
					}
				}
			}
		}
	}
`;

export default LoginPage;
